@import "intl-tel-input/build/css/intlTelInput.css";

.iti { width: 100%; }

.iti__selected-flag {
    border-radius: 25px 0 0 25px;
}

.iti__flag {background-image: url("~intl-tel-input/build/img/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("~intl-tel-input/build/img/flags@2x.png");}
}
